import { Link } from 'react-router-dom';
import '../styles/footer.css';
// import '../styles/base.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-links">
        {/* <Link to="/">
          <div className="App-link"> privacy policy</div>
        </Link> */}
        {/* <Link to="/AboutUs">
          <div className="App-link">about us</div>
        </Link> */}
      </div>
      {/* <br /> */}
      <center>This site does not use cookies</center>
      {/* <br /> */}
      <center>Patent-Pending, Copyright © 2024 SleepDev, Inc</center>
      <br />
    </div>
  );
}
export default Footer;
