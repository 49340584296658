import { Link } from 'react-router-dom';
import '../styles/landing.css';
import CompanyWhatWeDo from './CompanyWhatWeDo';

function Landing() {
  // const onClickOwlHandler = () => {
  //   console.log('you clicked the image');
  // };

  let audioOwl = new Audio('/sounds/owl_hoot_B1.mp3');
  // let audio = new Audio('/sounds/Ahhh2.mp3');

  const playOwlSound = () => {
    audioOwl.play();
  };

  // const onClickWolfHandler = () => {
  //   console.log('you clicked the image');
  // };

  let audioWolf = new Audio('/sounds/wolf_boris_2.m4a');
  // let audio = new Audio('/sounds/Ahhh2.mp3');

  const playWolfSound = () => {
    audioWolf.play();
  };

  return (
    <main className="page main-content">
      {/* <CompanyWhatWeDo /> */}
      <hr></hr>
      <section className="img-centered">
        <div className="sleeping-with-sDevice"></div>
      </section>
      <div className="hero-stripe">
        <div className="stripe-container-gutter-free">
          <div className="hero-container">
            <img
              className="hero-image"
              src="/images/lady-side-sleeping-lamp-left-half2.png"
              alt="lady sleeping on her side"
            />
            <div className="hero-details">
              <div className="stripe-header">
                We are working on a <br />
                novel positional therapy approach
                <br />
                for obstructive sleep apnea (OSA)
                <br />
                named <span className="accent_standard">WhisperPT</span>
              </div>
              <div className="stripe-copy">
                WhisperPT is based on the simple concept
                <br /> that patients with OSA deserve
                <br /> a treatment that is <br />
                comfortable, inexpensive, and effective.
              </div>
              {/* <div className="hero-copy">
                WhisperPT for Snoring
                <br />
              </div> */}
              {/* 
              <Link to="/learn-more">
                <div className="hero-button">learn more</div>{' '}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              Sleeping on their back increases OSA events
            </div>
            <div className="stripe-copy">
              For most OSA sufferers, sleeping on their back will cause their
              tongues and soft palates to fall to the back of the throat and
              block their airway.
            </div>
            <div className="sleeping-on-back-images-container">
              <div>
                <img
                  className="image"
                  src="/images/lying-on-back-in-place.gif"
                  alt="lying on back awake, soft palate and tongue stay in place"
                  width="250px"
                />
                <div className="caption">
                  as they lie down, <br />
                  the muscles hold the <br />
                  tongue and soft palate in place
                  <hr class="heavily-faded mobile-only" />
                </div>
              </div>
              <div>
                <img
                  className="image"
                  src="/images/lying-on-back-deep-sleep-collapsed.gif"
                  alt="lying on back in deep sleep, soft palate and tongue collapse"
                  width="250px"
                />
                <div className="caption">
                  when in deep sleep, <br />
                  the tongue and soft palate
                  <br />
                  collapse into their airway
                  <hr class="heavily-faded mobile-only" />
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div className="stripe color-stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <img
              className="side-sleeping-picture"
              src="/images/side-sleeping-mid-slice.jpg"
              alt="pleasant moment side sleeping"
              width="100%"
            />
            <div className="stripe-header positional-therapy-opens">
              Positional therapy opens their airway, reducing apnea events and
              severity
            </div>
            <div className="stripe-copy">
              Positional therapy (PT) keeps OSA sufferers sleeping on their side
              where the tongue and soft palate can stay clear of their airway,
              allowing them to breathe more freely.
            </div>
          </div>
        </div>
      </div>
      <div className="stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              We are developing a positional therapy device using sound cues
            </div>
            <div className="stripe-copy">
              Our patent-pending technology uses sound cues to notify sleepers
              when they roll onto and stop on their back. Since people primarily
              roll while in a light sleep, which is also when they are
              responsive to sound, our technology is designed to play soft
              notification sounds and guide them off their back without waking
              them.{' '}
            </div>
            <div className="max-benefit-container">
              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/ear-blue-256x256.png"
                    alt="ear icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  gentle sounds
                  <br />
                  guide the sleeper into side sleeping
                  <br />
                  before apnea events even start
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image side-icon"
                    src="/images/icons/on-side-blue-256x256.png"
                    alt="ear icon"
                    width="50px"
                  />
                </center>
                <div className="icon-caption">
                  reliable positional therapy
                  <br />
                  keeps them off their back
                  <br />
                  even in their deepest sleep
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/cues-blue-256x256.png"
                    alt="sound cues icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  dozens of pleasant
                  <br />
                  audio cues
                  <br />
                  to choose between
                </div>
              </div>
            </div>

            <div className="max-benefit-container">
              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/tiny-design-blue-256x256.png"
                    alt="tiny icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  tiny design
                  <br />
                  fits in the ear comfortably
                  <br />
                  through the night
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/app-blue-256x256.png"
                    alt="app icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  use presets, customizable
                  <br />
                  cue volume, and patterns
                  <br />
                  to align WhisperPT to their sleep
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/improve-blue-256x256.png"
                    alt="improvement icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  tune and improve their sleep
                  <br />
                  through logging and
                  <br />
                  auto-suggestions
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div className="stripe color-stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              Engineered to the smallest scale and modeled to fit comfortably in
              their ear
              <br />
            </div>
            <div className="engineered-to-the-smallest-scale-container">
              <div>
                <img
                  className="image lady-sleeping-device"
                  src="/images/lady-sleeping-with-device.png"
                  alt="lying on back in deep sleep, soft palate and tongue collapse"
                  width="100%"
                />
                <center>
                  <div className="caption">
                    with a 1/2 inch diameter - it's small, comfortable, and
                    discreet
                  </div>
                </center>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div className="stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              User WhisperPT sleep tests shows positional adherence and customer
              interest
              <br />
            </div>
            <div className="engineered-to-the-smallest-scale-container">
              <div>
                {' '}
                <center>
                  <img
                    className="image graph"
                    src="/images/product/Customer_Response_Data_Zoomed-in(dark).jpg"
                    alt="lying on back in deep sleep, soft palate and tongue collapse"
                    width="100%"
                  />

                  <div className="caption">
                    WhisperPT Sleep data demonstrating a tester responding to
                    the treatment and maintaining a side sleeping position
                  </div>
                </center>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div className="stripe color-stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              WhisperPT has not yet been cleared by the FDA
            </div>
            <br />
            <div className="stripe-copy">
              We have developed multiple functional prototypes of WhisperPT, and
              the latest versions have successfully passed Phase 1 testing. Upon
              completion of Phase 2 internal testing, we will proceed to
              clinical trials and submit a 510(k) application to the FDA.
              <p>
                &nbsp;
                <p />
                <p></p>
                Until WhisperPT receives FDA clearance, it cannot be sold in the
                United States.{' '}
              </p>
            </div>
            <br />
          </div>
        </div>
      </div>
    </main>
  );
}
export default Landing;
