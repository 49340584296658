import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/executivesummary.css';
import '../styles/FAQ.css';

function executiveSummaryConfidential() {
  return (
    <main className="page main-content">
      <br />
      <center>
        <div className="page-title">
          SleepDev Executive Summary Confidential
        </div>
        <div className="page-secondary-title">
          <span className="accent_primary_color_only">Objective:</span> Reduce
          mortality rates for millions of OSA sufferers
        </div>
      </center>
      {/* <hr className="faded" /> */}
      <br />
      <div className="flex-box-container">
        <section className="flex-box-item flex-box-item-1">
          <div>
            <div>
              <br />
              <table>
                <tr>
                  <td valign="top" className="accent_primary">
                    Problem
                  </td>
                  <td valign="top">
                    <ul>
                      <li>
                        936 million people suffer from obstructive sleep apnea
                        (OSA) worldwide
                      </li>
                      <li>
                        OSA is associated with heart disease, dementia, liver
                        disease, sleep deprivation, depression
                      </li>
                      <li>
                        55% - attrition rate for CPAP treatment after 3.5 yrs
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Solution
                  </td>
                  <td valign="top">
                    <ul>
                      <li>
                        56% - 75% of OSA patients have significant reduction of
                        obstructions using positional therapy (PT)
                      </li>
                      <li>
                        combining CPAP and PT reduces or eliminates several
                        complications driving CPAP attrition: aerophagia, GERD,
                        gas, mask leaks, palatal prolapse
                      </li>
                      <li>
                        we have combined CPAP and PT into a single mask system
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Market Analysis
                  </td>
                  <td valign="top">
                    <ul>
                      <li>
                        2021 CPAP mask market share: ResMed 50%, Philips 35%,
                        Fisher&Paykel 10%
                      </li>
                      <li>$1.4B - ResMed ’23 annual revenue on CPAP masks</li>
                      <li>6.3% - 7.1% - CAGR for CPAP masks</li>
                      <li>56% - 75% of OSA patients have positional OSA</li>
                      <li>
                        $2.3B - $3.2B - is our 2028 TAM for positional OSA CPAP
                        masks
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Business Model
                  </td>
                  <td valign="top">
                    <ul>
                      <li>
                        physicians prescribe CPAP and mask systems based on OSA
                        results from prescribed sleep reports
                      </li>
                      <li>
                        lab and home sleep reports highlight if a patient's OSA
                        is positional
                      </li>
                      <li>
                        we have the only positional obstructive sleep apnea mask
                        system for physicians to prescribe
                      </li>
                      <li>
                        existing payor codes for CPAP masks apply to our CPAP
                        components
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Traction
                  </td>
                  <td valign="top">
                    <ul>
                      <li>MVP (device and app) completed</li>
                      <li>
                        &gt;160 nights of testing with 4 people using the PT
                        CPAP Masks
                      </li>
                      <li>
                        &gt;3 years of development, iteration, and testing of
                        base PT device and code
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Intellectual Property
                  </td>
                  <td valign="top">
                    <ul>
                      <li>patentable & clear freedom to operate</li>
                      <li>
                        filed patents: SDEV-P001-PCT, PCT/US2023/0233133,
                        SDEV-P003Z, SDEV-P002Z
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Regulatory
                  </td>
                  <td valign="top">
                    <ul>
                      <li>
                        pre-assessment indicates clear path to 510K clearance
                        using 2 predicates
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Team
                  </td>
                  <td valign="top">
                    <ul>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/swertfager/"
                          target="blank"
                        >
                          <span className="App-link-color">
                            Michael Swertfager
                          </span>
                        </a>
                        : CEO &amp; co-founder; Apple PMO & Sr Program Manager
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/jamesgartrell/"
                          target="blank"
                        >
                          <span className="App-link-color">James Gartrell</span>
                        </a>
                        : CTO &amp; co-founder; Riot Games Technical Director
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/susanprohaska/"
                          target="blank"
                        >
                          <span className="App-link-color">
                            Susan Prohaska, PhD
                          </span>
                        </a>
                        : COO &amp; Head of Clinical Development; Jasper
                        Therapeutics, Forty Seven, Inc.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary">
                    Funding
                  </td>
                  <td valign="top">
                    <ul>
                      <li>
                        Seeking early stage funding for: patent prosecution, FDA
                        510 K clearance, design & manufacturing
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>
        {/* Source 3*/}
        <section>
          Functional MVP's
          <div className="boxed">
            <div className="section-title">
              <center>
                <img
                  className="hero-image"
                  src="/images/product/WPT-PAP_Low_Profile_and_High_Fidelity-on_black.jpg"
                  alt="WPT-PAP Low Profile and High Fidelity"
                  width="300px"
                />
              </center>
            </div>
          </div>
          <br />
          Integrated App
          <div className="boxed">
            <div className="section-title">
              <center>
                <img
                  className="hero-image"
                  src="/images/product/App_Screen_Shots.jpg"
                  alt="WPT-PAP Low Profile and High Fidelity"
                  width="300px"
                />
              </center>
            </div>
          </div>
          <br />
          {/* <div className="accent_primary">Testimonials</div> */}
          <br />
          <div>
            "Would you prescribe this?" <br />
            <span className="accent_standard">
              Dr Anil Rama (Sleep Medicine)
            </span>{' '}
            "Yes. It's a no-brainer."
          </div>
          <div></div>
          <br />
          <div>
            "If you were prescribed this, would you use it?" <br />
            <span className="accent_standard">
              Dave Sanders (following 11 night study)
            </span>{' '}
            "Absolutely."
          </div>
        </section>
      </div>
      <center>
        Potential investors can reach out to
        <a href="mailto:michael@SleepDev.com" className="App-link-color">
          {' '}
          michael@SleepDev.com
        </a>
      </center>
      <br />
    </main>
  );
}
export default executiveSummaryConfidential;
