import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/leadin.css';
import '../styles/FAQ.css';

function LeadIn() {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  // const onClickOwlHandler = () => {
  //   console.log('you clicked the image');
  // };

  let audioTone = new Audio('/sounds/tone-oscillating-medium_1.wav');
  const playToneSound = () => {
    audioTone.play();
  };

  let audioDove = new Audio('/sounds/dove-medium_1.wav');
  const playDoveSound = () => {
    audioDove.play();
  };

  let audioTrain = new Audio('/sounds/train-horn_single.wav');
  const playTrainSound = () => {
    audioTrain.play();
  };

  let audioOwl = new Audio('/sounds/owl_hoot_B1.mp3');
  const playOwlSound = () => {
    audioOwl.play();
  };

  let audioBowl = new Audio('/sounds/crystal-bowl-short_1.wav');
  const playBowlSound = () => {
    audioBowl.play();
  };

  return (
    <main className="page main-content">
      <br />
      <center>
        <div className="page-title">
          Positional snoring is the most common form of snoring. <br />
          Through positional therapy, snoring can be addressed before it starts.
        </div>
      </center>
      {/* <br /> */}
      {/* <hr className="faded" /> */}
      <br />
      <div className="flex-box-container">
        <section className="flex-box-item flex-box-item-1">
          <div>
            {/* <section className="sdevice-section"> */}

            <span className="section-title">The WhisperPT </span>
            <span>
              is a positional therapy device that plays gentle sound cues in
              your ear when you roll onto your back, encouraging you to continue
              onto your side
            </span>

            {/* <div></div>
            <div className="section-list">
              <ul>
                <li>fits discreetly in your ear</li>
                <li>
                  when you roll onto your back, it gently notifies you through
                  sounds cures to continue onto your side
                </li>
                <li>configurable notifcation cues, patterns, and angles</li>
              </ul>
            </div> */}
            {/* 
              <button type="button" className="button button-lead-in">
                <span className="button-text">
                  Stay looped in on <br />
                  WhisperPT's upcoming release
                </span>
                <span className="button-image">
                  <img
                    src="/images/SDevice-front-view.png"
                    alt="SDevice-front-view"
                    width="90px"
                    className="product-image"
                  ></img>
                </span>
              </button>
            </section> */}
            <div className="accordion">
              <div>
                <input
                  type="checkbox"
                  name="FAQ_accordion"
                  id="section-one"
                  className="accordion__input"
                />
                {/* ACCORDION INSERT */}
                <label htmlFor="section-one" className="accordion__label">
                  notifies you as you move onto your back
                </label>
                <div className="accordion__content">
                  <ul>
                    <li>
                      WhisperPT detects when you roll onto your back, and plays
                      a sound cue - guiding you to your side
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="FAQ_accordion"
                  id="section-two"
                  className="accordion__input"
                />
                {/* ACCORDION INSERT 2 */}
                <label htmlFor="section-two" className="accordion__label">
                  gentle sound cues notifications
                </label>
                <div className="accordion__content">
                  <ul>
                    <li>
                      below are some samples of the dozens of gentle cues
                      provided
                      <span
                        className="img-wrapper img-owl"
                        onClick={playToneSound}
                      >
                        <div className="content fade sound-button">
                          simple tone 🔊{' '}
                        </div>
                      </span>
                      <span
                        className="img-wrapper img-owl"
                        onClick={playDoveSound}
                      >
                        <div className="content fade sound-button">
                          dove 🔊{' '}
                        </div>
                      </span>
                      <span
                        className="img-wrapper img-owl"
                        onClick={playTrainSound}
                      >
                        <div className="content fade sound-button">
                          train 🔊{' '}
                        </div>
                      </span>
                      <span
                        className="img-wrapper img-owl"
                        onClick={playOwlSound}
                      >
                        <div className="content fade sound-button">owl 🔊 </div>
                      </span>
                      <span
                        className="img-wrapper img-owl"
                        onClick={playBowlSound}
                      >
                        <div className="content fade sound-button">
                          crystal bowl 🔊{' '}
                        </div>
                      </span>
                    </li>
                    <li>
                      the sounds are designed to provide an effective but
                      pleasant notification with minimal disruption to your
                      sleep
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion">
              <input
                type="checkbox"
                name="FAQ_accordion"
                id="section-three"
                className="accordion__input"
              />
              {/* ACCORDION INSERT 3*/}
              <label htmlFor="section-three" className="accordion__label">
                configurable notification angles and patterns
              </label>
              <div className="accordion__content">
                <ul>
                  <li>
                    the SleepDev app lets you choose your sounds and test out
                    your settings when in bed
                  </li>
                  <li>lay on your back to set the notification angle</li>
                  <li>
                    adjust the patterns that works best for you (E.G)
                    <li>starting cue volume</li>
                    <li>increased volume as the cue repeats</li>
                    <li>delays between repeated cues</li>
                  </li>
                  <li>
                    or choose from our presets until you know your sleep style
                  </li>
                  <li>
                    additional features:
                    <li>
                      delay timer for reading on your back before going to sleep
                    </li>
                    <li>
                      in-ear audio instructions when configuring patterns and
                      choosing sound cues
                    </li>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion">
              <input
                type="checkbox"
                name="FAQ_accordion"
                id="section-four"
                className="accordion__input"
              />
              {/* ACCORDION INSERT 3*/}
              <label htmlFor="section-four" className="accordion__label">
                SleepDev PT app
              </label>
              <div className="accordion__content">
                <ul>
                  <li>
                    the accompanying SleepDev app connects to the WhisperPT
                    while in your ear for an easier set-up:
                    <li>lay on your back and set your notification angle</li>
                    <li>listen and choose your sound cue for the night</li>
                    <li>
                      try out your pattern settings in bed and adjust right
                      there on the app
                    </li>
                  </li>
                  <li>
                    in the morning, dock the WhisperPT and upload the night's
                    sleep log to your app
                  </li>
                  <li>
                    the app provides your sleep summary, how well you responded,
                    and what you can do to improve your positional therapy
                  </li>
                </ul>
              </div>
            </div>
            {/* Source 2*/}
            {/* <div className="section-title">
              Snoring can be a significant problem in many people’s lives:
            </div>
            <div className="section-list">
              <ul>
                <li>loud snoring can disturb yours and your partner's sleep</li>
                <li>
                  sleep deprivation can affect moods, judgement, cognition, and
                  increase anxiety
                </li>
              </ul>
            </div> */}
          </div>
        </section>
        {/* Source 3*/}
        <section>
          <div className="boxed">
            <div className="section-title">
              I would like to receive updates on WhisperPT's upcoming release
            </div>
            <div>
              <form
                action="https://formsubmit.co/mswertfager@gmail.com"
                method="POST"
              >
                <div className="register-form">
                  <div>
                    <input type="text" name="name" required />
                    <br />
                    <span className="field-label">full name</span>
                  </div>
                  <div>
                    <input type="email" name="email" size="30" required />
                    <br />
                    <span className="field-label">email address</span>
                  </div>
                </div>
                <button type="submit" className="submit-button">
                  send
                </button>
              </form>
            </div>
            <div className="privacy-statement">
              Your info will <u>only</u> be used for WhisperPT release updates.
            </div>
          </div>
        </section>
      </div>
      <br />
    </main>
  );
}
export default LeadIn;
