import { Link } from 'react-router-dom';
import '../styles/errors.css';

function Errors() {
  return (
    <main className="page main-content">
      <h1>Page cannot be found</h1>
      <br />
      <div className="return-home">
        <Link to="/">
          <div className="App-link">return home</div>
        </Link>
      </div>
    </main>
  );
}
export default Errors;
