import { Link } from 'react-router-dom';
import '../styles/FAQ.css';
import '../App.css';
import '../styles/about_us.css';

function PositionalTherapyFAQ() {
  return (
    <main className="page main-content gutters">
      <div className="page-title">Positional Therapy FAQ</div>
      <div className="">
        <div className="FAQ-subheader">What is positional therapy?</div>
      </div>
      <p className="">
        <span className="emphasis accent-color">Positional therapy</span> is a
        behavioral sleep strategy that involves encouraging patients to sleep on
        their side or stomach instead of their backs. Clinical studies have
        revealed benefits of using positional therapy for various medical
        conditions/diseases.
      </p>
      <div className="FAQ-flex-box-container">
        <p className="FAQ-flex-box-caption-top">
          On your back in deep sleep, your tongue and soft palate can collapse
        </p>
        <img
          className="illustration-med FAQ-flex-box-illustration"
          src="/images/reference/SleepingSupine_HeavyFrame.jpg"
          alt="Back Sleeping"
          width="15%"
          Height="15%"
        />{' '}
        <img
          className="illustration-med FAQ-flex-box-illustration"
          src="/images/reference/SleepingOnSide_HeavyFrame.jpg"
          alt="Side Sleeping"
          width="15%"
          Height="15%"
        />
        <p className="FAQ-flex-box-caption-bottom">
          On your side, your airway remains open
        </p>
      </div>
      <br />
      <div className="">
        <div className="FAQ-subheader">
          How does positional therapy reduce{' '}
          <Link to="/LeadIn">
            <span className="">obstructive sleep apnea (OSA)</span>
          </Link>
        </div>
        <div className="">
          Most people with OSA will receive a significant reduction in apnea
          events by not sleeping on their back
          <ul>
            <li>
              When transitioning into a deep sleep,
              <span className="emphasis accent-color">
                {' '}
                your tongue and soft-palate relax
              </span>
            </li>
            <li>
              With{' '}
              <span className="emphasis accent-color">
                {' '}
                gravity, your tongue and soft palate fall
              </span>{' '}
              into your airway
            </li>
            <li>
              This obstructs air from getting to your lungs,{' '}
              <span className="emphasis accent-color">
                causing suffocation while you sleep
              </span>
            </li>
            <li>
              By{' '}
              <span className="emphasis accent-color">
                sleeping on your side
              </span>{' '}
              and staying off your back, this blockage can be avoided{' '}
              <span className="emphasis accent-color">
                and you can breathe more clearly
              </span>
            </li>
          </ul>
        </div>
      </div>

      <br />
      <div>
        <div className="FAQ-subheader">
          What conditions and diseases does positional therapy help treat?
        </div>
        <p>
          <table className="FAQ-table">
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Obstructive Sleep Apnea
                <br />
                (OSA)
              </td>
              <td valign="top">
                <div className="padded-text FAQ-light-text">
                  "Conclusion: An approximate 56% of patients with OSA have
                  position-dependent OSA commonly defined as a difference of 50%
                  or more in apnea index between supine and non-supine
                  positions...A great deal is to be gained from treating
                  patients with POSA (positional obstructive sleep apnea) with
                  PT (positional therapy)."
                </div>
                <div className="padded-text">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3575552/pdf/11325_2012_Article_683.pdf"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    M. J. L. Ravesloot, et al, The undervalued potential of
                    positional therapy in position-dependent snoring and
                    obstructive sleep apnea — a review of the literature, Sleep
                    Breath 2013; 17:39-49
                  </a>
                </div>
              </td>
            </tr>
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Central Sleep Apnea
                <br />
                (CSA)
              </td>
              <td valign="top">
                <div className="padded-text">
                  "Conclusion: SPT (sleep positonal therapy) in PCSA (positional
                  central sleep apnea) is efficacious and can be considered as a
                  new treatment modality."
                </div>
                <div className="padded-text">
                  <a
                    href="https://erj.ersjournals.com/content/48/suppl_60/PA2388"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    Linda Benoist, et al, Positional therapy as a treatment for
                    central sleep apnoea: A prospective multicentre trial,
                    European Respiratory Journal 2016 48: PA2388
                  </a>
                </div>
              </td>
            </tr>
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Snoring
              </td>
              <td valign="top">
                <div className="padded-text">
                  "In conclusion, the results of our short-term study
                  demonstrated that the use of a HPP (head positioning pillow)
                  significantly reduced the subjective and objective severity of
                  snoring in adult patients with mild-to-moderate positional
                  OSAS, with an acceptable safety profile. Positional therapy
                  was associated with subjective improvements of snoring
                  regardless of BMI."
                </div>
                <div className="padded-text">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4676069/"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    Wen-Chyuan Chen, et al, Treatment of snoring with positional
                    therapy in patients with positional obstructive sleep apnea
                    syndrome, Sci Rep. 2015; 5: 18188
                  </a>
                </div>
              </td>
            </tr>
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Neurodegenerative Diseases
              </td>
              <td valign="top">
                <div className="padded-text">
                  "Conclusions: We found that home supine sleep position was
                  independently associated with neurodegenerative disease. Our
                  findings suggest the intriguing possibility that head position
                  during sleep could influence the clearance of neurotoxic
                  proteins from the brain."
                </div>
                <div className="padded-text">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6398535/"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    Daniel J. Levendowski, et al, Head Position During Sleep:
                    Potential Implications for Patients with Neurodegenerative
                    Disease, J Alzheimers Dis. 2019; 67(2): 631–638
                  </a>
                </div>
              </td>
            </tr>
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Gastroesophageal Reflux Disease
                <br />
                (GERD)
              </td>
              <td valign="top">
                <div className="padded-text">
                  "Conclusion: Current evidence suggests that sleeping on the
                  left side could reduce nocturnal reflux and improve
                  GERD-related quality of life, therefore warranting
                  interventions that promote LLD (left lateral decubitus) sleep
                  position."
                </div>
                <div className="padded-text accent-color">
                  <a
                    href="www.ncbi.nlm.nih.gov/pmc/articles/PMC10643078/"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    Daniel Martin Simadibrata, et al, Left lateral decubitus
                    sleeping position is associated with improved
                    gastroesophageal reflux disease symptoms: A systematic
                    review and meta-analysis, World J Clin Cases. 2023 Oct 26;
                    11(30): 7329–7336.
                  </a>
                </div>
              </td>
            </tr>
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Chronic Obstructive Pulmonary Disease
                <br />
                (COPD)
              </td>
              <td valign="top">
                <div className="padded-text">
                  "The supine posture is mechanically disadvantageous to
                  pulmonary mechanics in healthy subjects and has additional
                  implications in COPD..."
                  <br />
                  "Sleep imposes additional burdens to the respiratory muscle
                  pump, driven by changes in respiratory muscle tone, neural
                  respiratory drive and consequences of the supine position."
                </div>
                <div className="padded-text accent-color">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7642631/"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    "Rebecca F. D’Cruz, et al, Sleep disordered breathing and
                    chronic obstructive pulmonary disease: a narrative review on
                    classification, pathophysiology and clinical outcomes, J
                    Thorac Dis. 2020 Oct; 12(Suppl 2): S202–S216."
                  </a>
                </div>
              </td>
            </tr>
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Late Stage Pregnancy
              </td>
              <td valign="top">
                <div className="padded-text">
                  "Conclusions: Results provide preliminary evidence that an
                  intervention to reduce supine sleep in late pregnancy may
                  provide maternal and fetal health benefits, with minimal
                  effect on maternal perception of sleep quality and objectively
                  estimated sleep time."
                </div>
                <div className="padded-text">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6086963/"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    Jane Warland, et al, Modifying Maternal Sleep Position in
                    Late Pregnancy Through Positional Therapy: A Feasibility
                    Study, J Clin Sleep Med. 2018 Aug 15; 14(8): 1387–1397
                  </a>
                </div>
              </td>
            </tr>
            <tr className="info-block-container">
              <td valign="top" className="padded-text accent-color">
                Benign Paroxysmal Positional Vertigo
                <br />
                (BPPV)
              </td>
              <td valign="top">
                <div className="padded-text">
                  "Our study demonstrated that sleep position after a
                  repositioning maneuver has statistically significant impact on
                  the recurrence rate of BPPV. The patients sleeping on their
                  affected side after the repositioning maneuver had a higher
                  recurrence rate than patients with other sleep positions."
                </div>
                <div className="padded-text">
                  <a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3867465/"
                    className="FAQ-article-citation"
                    target="blank"
                  >
                    Shufeng Li, et al, Impact of Postmaneuver Sleep Position on
                    Recurrence of Benign Paroxysmal Positional Vertigo, PLoS
                    One. 2013; 8(12): e83566.
                  </a>
                </div>
              </td>
            </tr>
          </table>
        </p>
      </div>
      <div className="FAQ-subheader">Who recommends positional therapy?</div>
      <p className="">
        Most hospitals and sleep specialist recommend positional therapy as a
        1st tier treatment for obstructive sleep apnea.
      </p>
      <div className="FAQ-flex-box-container">
        <img
          className="FAQ-hosptial-logo"
          src="/images/icons/Stanford_Medical_logo.jpg"
          alt="Stanford Medical"
        />
        <img
          className="FAQ-hosptial-logo"
          src="/images/icons/Mount_Sinai_logo.jpg"
          alt="Mt Sinai"
        />
        <img
          className="FAQ-hosptial-logo"
          src="/images/icons/MayoClinic_side_logo.jpg"
          alt="MayoClinic"
        />
        <img
          className="FAQ-hosptial-logo"
          src="/images/icons/Johns_Hopkins_logo.jpg"
          alt="Johns_Hopkins"
        />
      </div>
      {/* <div className="accordion">
        <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-apnea"
            className="accordion__input"
          />
          <label
            htmlFor="section-apnea"
            className="accordion__label FAQ-subheader"
          >
            How does positional therapy help sleep apnea?
          </label>
          <div className="accordion__content">
            <p>
              <br />
              Snoring is caused by a partial obstruction of soft tissues in the
              airway including the back of the mouth, throat, tongue,
              epiglottis, uvula, tonsils, and soft palate. Extra fatty tissue
              around the neck in someone that’s obese can also play a role.
            </p>
          </div>
        </div>

        <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-two"
            className="accordion__input"
          />
          <label htmlFor="section-two" className="accordion__label">
            Medical postitional therapy studies...
          </label>
          <div className="accordion__content">
            <div className="section-title">
              The undervalued potential of positional therapy in
              position-dependent snoring and obstructive sleep apnea
            </div>
            <div className="article-summary">
              Long-term compliance for PT remains an issue, and although
              remarkable results have been shown using innovative treatment
              concepts for PT, there is room for both technical improvement of
              the devices and for further research...
            </div>
            <div className="article-citation">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3575552"
                target="blank"
              >
                National Library of Medicine
              </a>
              : M. J. L. Ravesloot, PhD; J. P. van Maanen, PhD; Dr. L. Dun, Dr.
              N. de Vries
            </div>
            <br />

            <div className="section-title">
              Modifying Maternal Sleep Position in Late Pregnancy Through
              Positional Therapy: A Feasibility Study
            </div>
            <div className="article-summary">
              Results provide preliminary evidence that an intervention to
              reduce supine sleep in late pregnancy may provide maternal and
              fetal health benefits, with minimal effect on maternal perception
              of sleep quality and objectively estimated sleep time...
            </div>
            <div className="article-citation">
              <a
                href="https://jcsm.aasm.org/doi/10.5664/jcsm.7280"
                target="blank"
              >
                Journal of Clinical Sleep Medicine
              </a>
              : Jane Warland, PhD; Jillian Dorrian, PhD; Allan J. Kember, BSc;
              Craig Phillips, PhD , Ali Borazjani, PhD , Janna L. Morrison, PhD;
              Louise M. O'Brien, PhD
            </div>
            <br />

            <div className="section-title">
              Positional therapy for obstructive sleep apnoea
            </div>
            <div className="article-summary">
              The review found that CPAP has a greater effect on improving AHI
              compared with positional therapy in positional OSA, while
              positional therapy was better than inactive control for improving
              ESS and AHI. Positional therapy may have better adherence than
              CPAP...
            </div>
            <div className="article-citation">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6491901/"
                target="blank"
              >
                National Library of Medicine
              </a>
              : P R Srijithesh,corresponding author Rajeswari Aghoram, Amit
              Goel, Jayaraj Dhanya, and Cochrane Airways Group
            </div>
            <br />

            <div className="section-title">
              Usage of Positional Therapy in Adults with Obstructive Sleep Apnea
            </div>
            <div className="article-summary">
              Positional therapy is an effective method to treat patients with
              positional OSA on the short-term. Long-term compliance is low
              especially in patients with moderate OSA at baseline. More
              comfortable devices such as vibrating devices might be more useful
              to treat positional OSA...
            </div>
            <div className="article-citation">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6491901/"
                target="blank"
              >
                National Library of Medicine
              </a>
              : Grietje E. de Vries, MSc; Aarnoud Hoekema, MD, PhD; Michiel H.J.
              Doff, DMD, PhD; Huib A.M. Kerstjens, MD, PhD; Petra M. Meijer, NP;
              Johannes H. van der Hoeven, MD, PhD; and Peter J. Wijkstra, MD,
              PhD
            </div>
            <br />
          </div>
        </div>

        <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-three"
            className="accordion__input"
          />
          <label
            htmlFor="section-three"
            className="accordion__label FAQ-subheader"
          >
            What conditions and diseases is postitional therapy often
            recommended for by doctors?
          </label>
          <div className="accordion__content">
            <ul>
              <li>positional snoring</li>
              <li>positional obstructive sleep apnea</li>
              <li>gastroesophageal reflux disease GERD</li>
              <li>3rd trimester of pregnancy</li>
              <li>back issues</li>
              <li>benign paroxysmal positional vertigo (BPPV)</li>
            </ul>
          </div>
        </div>
      </div> */}
      {/* <br />
      <Link to="/LeadIn">
        <button type="button" className="button button-landing">
          <span className="button-text">Learn more about the sDevice...</span>
        </button>
      </Link> */}
    </main>
  );
}

export default PositionalTherapyFAQ;
