import { Link } from 'react-router-dom';
import '../styles/landing.css';
import CompanyWhatWeDo from './CompanyWhatWeDo';

function Landing() {
  // const onClickOwlHandler = () => {
  //   console.log('you clicked the image');
  // };

  let audioOwl = new Audio('/sounds/owl_hoot_B1.mp3');
  // let audio = new Audio('/sounds/Ahhh2.mp3');

  const playOwlSound = () => {
    audioOwl.play();
  };

  // const onClickWolfHandler = () => {
  //   console.log('you clicked the image');
  // };

  let audioWolf = new Audio('/sounds/wolf_boris_2.m4a');
  // let audio = new Audio('/sounds/Ahhh2.mp3');

  const playWolfSound = () => {
    audioWolf.play();
  };

  return (
    <main className="page main-content">
      <CompanyWhatWeDo />
      <hr></hr>
      <section className="img-centered">
        <div className="sleeping-with-sDevice"></div>
      </section>
      <div className="hero-stripe">
        <div className="stripe-container-gutter-free">
          <div className="hero-container">
            <img
              className="hero-image"
              src="/images/lady-side-sleeping-lamp-left-half2.png"
              alt="lady sleeping on her side"
            />
            <div className="hero-details">
              <div className="hero-header">
                Stop snoring through
                <br />
                positonal therapy
              </div>
              <div className="hero-copy">
                WhisperPT for Snoring
                <br />
                {/* <br />
                coming soon */}
              </div>

              <Link to="/learn-more">
                <div className="hero-button">learn more</div>{' '}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              Sleeping on your back increases snoring
            </div>
            <div className="stripe-copy">
              When sleeping on your back, your tongue and soft palate drop
              towards the back of your throat, causing air resistance in your
              airway. During deeper sleep, as air squeezes through the narrowed
              airway the soft tissues in your throat vibrate and loud snoring
              can occur.
            </div>
            <div className="sleeping-on-back-images-container">
              <div>
                <img
                  className="image"
                  src="/images/lying-on-back-in-place.gif"
                  alt="lying on back awake, soft palate and tongue stays in place"
                  width="250px"
                />
                <div className="caption">
                  as you lay down, <br />
                  the muscles hold the <br />
                  tongue and soft palate in place
                  <hr class="heavily-faded mobile-only" />
                </div>
              </div>
              <div>
                <img
                  className="image"
                  src="/images/lying-on-back-deep-sleep-collapsed.gif"
                  alt="lying on back in deep sleep, soft palate and tongue collapse"
                  width="250px"
                />
                <div className="caption">
                  when sleeping, <br />
                  the tongue and soft palate often
                  <br />
                  drop narrowing your airway
                  <hr class="heavily-faded mobile-only" />
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div className="stripe color-stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <img
              className="side-sleeping-picture"
              src="/images/side-sleeping-mid-slice.jpg"
              alt="pleasant moment side sleeping"
              width="100%"
            />
            <div className="stripe-header positional-therapy-opens">
              Positional therapy opens your airway, reducing snoring
            </div>
            <div className="stripe-copy">
              Positional therapy (PT) keeps you sleeping on your side where your
              tongue and soft palate can stay clear of your airway, allowing you
              to breathe more freely and without snoring. This is most important
              when you are in a deep sleep.
            </div>
          </div>
        </div>
      </div>
      <div className="stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              WhisperPT gives you the maximum benefits of positional therapy
            </div>
            <div className="max-benefit-container">
              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/ear-blue-256x256.png"
                    alt="ear icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  gentle sounds
                  <br />
                  guide you into side sleeping
                  <br />
                  before you start snoring
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image side-icon"
                    src="/images/icons/on-side-blue-256x256.png"
                    alt="ear icon"
                    width="50px"
                  />
                </center>
                <div className="icon-caption">
                  reliable positional therapy
                  <br />
                  keeps you off your back
                  <br />
                  even in your deepest sleep
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/cues-blue-256x256.png"
                    alt="sound cues icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  dozens of pleasant
                  <br />
                  audio cues
                  <br />
                  to choose between
                </div>
              </div>
            </div>

            <div className="max-benefit-container">
              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/tiny-design-blue-256x256.png"
                    alt="tiny icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  tiny design
                  <br />
                  fits in your ear comfortably
                  <br />
                  through the night
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/app-blue-256x256.png"
                    alt="app icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  use presets, customizable
                  <br />
                  cue volume, and patterns
                  <br />
                  to align WhisperPT to your sleep
                </div>
              </div>

              <div className="icon-container">
                <center>
                  <img
                    className="icon-image"
                    src="/images/icons/improve-blue-256x256.png"
                    alt="improvement icon"
                    width="40px"
                  />
                </center>
                <div className="icon-caption">
                  tune and improve your sleep
                  <br />
                  through logging and
                  <br />
                  auto-suggestions
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div className="stripe color-stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <div className="stripe-header">
              Engineered to the smallest scale and modeled to fit comfortably in
              your ear
              <br />
            </div>
            <div className="engineered-to-the-smallest-scale-container">
              <div>
                <img
                  className="image lady-sleeping-device"
                  src="/images/lady-sleeping-with-device.png"
                  alt="lying on back in deep sleep, soft palate and tongue collapse"
                  // width="500px"
                  width="100%"
                />
                <center>
                  <div className="caption">
                    at 0.6 x 1.1 inches it's small, comfortable, and discreet
                  </div>
                </center>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div className="stripe">
        <div className="stripe-container">
          <div className="stripe-contents">
            <center>
              <div className="stripe-header">
                {/* WhisperPT is releasing this winter */}
                &nbsp;
              </div>
              <div>
                <Link to="/learn-more">
                  <div className="closing-button">
                    receive updates on the upcoming WhisperPT release
                  </div>{' '}
                </Link>
              </div>
              <br />
              <div>
                Your info will <u>only</u> be used for the information you
                request
              </div>
            </center>

            <br />
          </div>
        </div>
      </div>
    </main>
  );
}
export default Landing;
