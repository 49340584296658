import { Link } from 'react-router-dom';
import '../styles/footer.css';
// import '../styles/base.css';

function CompanyWhatWeDo() {
  return (
    <div className="announcement">
      <div>
        SleepDev makes therapy devices to identify and treat sleep issues.
        <ol>
          <li>
            WhisperPT for Snoring: Treats snoring through positional therapy
          </li>
          <li>WhisperPT for Apnea: in development</li>
          {/* <li>WhisperPT for Apnea diagnosis: in development</li> */}
        </ol>
      </div>
    </div>
  );
}
export default CompanyWhatWeDo;
