import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/executivesummary.css';
import '../styles/FAQ.css';

function executiveSummary() {
  return (
    <main className="page main-content">
      <center>
        <div className="page-title">Executive Summary</div>
        <div className="page-secondary-title">
          <span className="accent_primary_color_only">Objective:</span> Reduce
          mortality rates for millions of OSA sufferers
        </div>
      </center>
      {/* <hr className="faded" /> */}
      <br />
      <div className="flex-box-container">
        <section className="flex-box-item flex-box-item-1">
          <div>
            <div>
              <br />
              <table>
                <tr>
                  <td valign="top" className="accent_primary" colspan="2">
                    Medical Device for Obstructive Sleep Apnea (OSA)
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td valign="top">
                    <ul>
                      <li>
                        Significantly increases CPAP adherence for patients
                      </li>
                      <li>
                        ...reducing early mortality rates for millions of OSA
                        sufferers
                      </li>
                      <li>
                        $2.3B to $3.2B <br />
                        (our Total Addressable Market by 2028)
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary" colspan="2">
                    Business Traction
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td valign="top">
                    <ul>
                      <li>3 Prototypes completed July 2023</li>
                      <li>Intellectual Property: 4 patents filed </li>
                      <li>
                        Regulatory: pre-assessment indicates clean path to 510 K
                        clearance using 2 predicates
                      </li>
                      <li>
                        Base technology internally tested across more than 700
                        nights of sleep
                      </li>
                      <li>
                        Prototype: internal testing over 90 nights, external 3rd
                        party testing over 30 nights
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary" colspan="2">
                    Medical Advisors
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td valign="top">
                    <ul>
                      <li>
                        Anil Rama MD: Neurologist & Sleep Medicine - Sleep and
                        Brain Medicine, Stanford Medical School{' '}
                      </li>
                      <li>
                        Aditya Chandrasekar MD: MPH, FACP, CPE: Medical Director
                        - Primary Health; Harvard Medical School; Johns Hopkins
                      </li>
                      <li>
                        Bryan Chesen MD, Pulmonology & Sleep Medicine - NYC
                        Health + Hospitals
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary" colspan="2">
                    Testimonials
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td valign="top">
                    <div className="indent">
                      <div>
                        "Would you prescribe this?" <br />
                        <span className="accent_standard">
                          Dr Anil Rama (Sleep Medicine)
                        </span>{' '}
                        "Yes. It's a no-brainer."
                      </div>

                      <br />

                      <div>
                        "If you were prescribed this, would you use it?" <br />
                        <span className="accent_standard">
                          Dave Sanders (following 11 night study)
                        </span>{' '}
                        "Absolutely."
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <span>
                      <hr className="heavily_faded" />
                    </span>
                  </td>
                </tr>

                <tr>
                  <td valign="top" className="accent_primary" colSpan="2">
                    Funding Request
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td valign="top">
                    <ul>
                      <li>
                        Seeking early stage funding for: patent prosecution, FDA
                        510 K clearance, design & manufacturing
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>
        {/* Source 3*/}
        <section>
          &nbsp;
          <div className="boxed">
            <div className="team-member-block-small">
              <img
                className="team-image-small"
                src="/images/team/Employee_Michael.jpg"
                alt="Michael Swertfager"
              />
              <br />
              Michael Swertfager
              <br />
              Chief Executive Officer
              <br />
              &amp; co-founder
              <br />
              <a href="https://www.linkedin.com/in/swertfager/" target="blank">
                <img
                  className="linkedIn-icon"
                  src="/images/icons/LI-In-Bug.png"
                  alt="to Michael Swertfager's LinkedIn"
                />
              </a>
            </div>

            <div className="team-member-block-small">
              <img
                className="team-image-small"
                src="/images/team/Employee_James.jpg"
                alt="James Gartrell"
              />
              <br />
              James Gartrell
              <br />
              Chief Technology Officer
              <br />
              &amp; co-founder
              <br />
              <a
                href="https://www.linkedin.com/in/jamesgartrell/"
                target="blank"
              >
                <img
                  className="linkedIn-icon"
                  src="/images/icons/LI-In-Bug.png"
                  alt="to James Gartrell's LinkedIn"
                />
              </a>
            </div>

            <div className="team-member-block-small">
              <img
                className="team-image-small"
                src="/images/team/Employee_Susan.jpg"
                alt="Susan Prohaska"
              />
              <br />
              Susan Prohaska, PhD
              <br />
              Chief Operating Officer
              <br />
              &amp; Head of Clinical Development
              <br />
              <a
                href="https://www.linkedin.com/in/susanprohaska/"
                target="blank"
              >
                <img
                  className="linkedIn-icon"
                  src="/images/icons/LI-In-Bug.png"
                  alt="to Susan Prohaska's LinkedIn"
                />
              </a>
            </div>
          </div>
        </section>
      </div>
      <br></br>
      <div>
        <hr className="heavily_faded" />
      </div>
      <br></br>
      <center>
        Potential investors can reach out to
        <a href="mailto:michael@SleepDev.com" className="App-link-color">
          {' '}
          michael@SleepDev.com
        </a>
      </center>
      <br />
    </main>
  );
}
export default executiveSummary;
